import { Box, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { render } from "datocms-structured-text-to-plain-text"
import React from "react"
import Link from "./utils/link"
import { getArticlePath } from "../utils/path"

const ArticleThumb = ({ article }) => {
  return (
    <Link
      to={`/${getArticlePath(article.slug, article.locale)}`}
      variant="articleThumb"
    >
      <Box>
        <Box mb={0}>
          <GatsbyImage image={article.image.gatsbyImageData} alt="" />
        </Box>
        <Box>
          <Box mb={3} sx={{ width: "100%" }}>
            <Text variant="caption" mr={2}>
              {article.category.name}
            </Text>
            <Text variant="date" color="primary">
              {article.date}
            </Text>
          </Box>
          {article.title && (
            <Text as="h4" mb={2} sx={{ color: "dark", fontWeight: 700 }}>
              {article.title.length > 90
                ? article.title.substring(0, 90) + " ..."
                : article.title}
            </Text>
          )}
          <Text as="p" sx={{ fontSize: 1 }} mb={[3, 5]}>
            {render(article.structuredBody)
              ? render(article.structuredBody).substring(0, 120) + " ..."
              : ""}
          </Text>
        </Box>
      </Box>
    </Link>
  )
}

export default ArticleThumb
