import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import FeaturedArticles from "../components/featuredArticles"
import CategorySection from "../components/categorySection"
import FeaturedBanner from "../components/featuredBanner"
import SEO from "../components/seo"
import LatestEdition from "../components/latestEdition"
import { useBreakpointIndex } from "@theme-ui/match-media"
import CategorySectionMobile from "../components/categorySectionMobile"
import { LocaleContext } from "../context/localeContext"
import { i18nContext, languages } from "../context/i18nContext"

const Home = ({ data: { home, articles }, location }) => {
  // console.log(home)
  const breakpointIndex = useBreakpointIndex()

  return (
    <LocaleContext.Provider
      value={{
        activeLocale: home.locale,
        paths: [
          {
            locale: "en",
            value: "en",
          },
          {
            locale: "it",
            value: "",
          },
        ],
      }}
    >
      <i18nContext.Provider value={languages[home.locale]}>
        <Layout locale={home.locale} location={location}>
          <SEO lang={home.locale} title="Home" />
          {home.content.map(section => (
            <section key={section.id}>
              {section.model.apiKey === "articles_category" && (
                <>
                  {breakpointIndex === 0 ? (
                    <CategorySectionMobile
                      categories={section.categories}
                      articles={articles}
                    />
                  ) : (
                    <CategorySection
                      categories={section.categories}
                      articles={articles}
                    />
                  )}
                </>
              )}
              {section.model.apiKey === "articles_featured" && (
                <FeaturedArticles
                  title={section.title}
                  articles={section.articles}
                />
              )}
              {section.model.apiKey === "featured_banner" && (
                <FeaturedBanner banners={section.banners} />
              )}
              {section.model.apiKey === "latest_edition" && (
                <LatestEdition
                  banner={section.banner}
                  image={section.image}
                  locale={home.locale}
                />
              )}
            </section>
          ))}
        </Layout>
      </i18nContext.Provider>
    </LocaleContext.Provider>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    articles: allDatoCmsArticle(
      filter: { locale: { eq: $locale }, slug: { ne: null } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        locale
        slug
        category {
          id
          name
        }
        date(locale: $locale, formatString: "DD MMMM Y")
        image {
          gatsbyImageData(
            width: 1230
            height: 820
            placeholder: BLURRED
            imgixParams: { fit: "crop" }
          )
        }
        structuredBody {
          value
        }
      }
    }
    home: datoCmsHomePage(id: { eq: $id }) {
      id
      originalId
      locale
      content {
        ... on DatoCmsArticlesCategory {
          id
          model {
            apiKey
          }
          categories {
            id
            name
            slug
            locale
            homePageItems
          }
        }
        ... on DatoCmsArticlesFeatured {
          id
          title
          model {
            apiKey
          }
          articles {
            id
            title
            locale
            slug
            category {
              id
              name
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
            image {
              gatsbyImageData(
                width: 1230
                height: 820
                placeholder: BLURRED
                imgixParams: { fit: "crop" }
              )
            }
            structuredBody {
              value
            }
          }
        }
        ... on DatoCmsFeaturedBanner {
          id
          model {
            apiKey
          }
          banners {
            id
            title
            url
            anchorText
            pdf {
              url
            }
            image {
              gray: gatsbyImageData(
                width: 1200
                height: 800
                placeholder: BLURRED
                imgixParams: { fit: "crop" }
              )
              blue: gatsbyImageData(
                width: 1200
                height: 800
                placeholder: BLURRED
                imgixParams: { fit: "crop" }
              )
            }
          }
        }
        ... on DatoCmsLatestEdition {
          id
          model {
            apiKey
          }
          banner {
            category {
              locale
              id
              slug
            }
            id
            title
            url
            anchorText
            pdf {
              url
            }
            image {
              gray: gatsbyImageData(
                width: 1200
                height: 800
                placeholder: BLURRED
                imgixParams: { fit: "crop", duotone: "000000,cccccc" }
              )
              blue: gatsbyImageData(
                width: 1200
                height: 800
                placeholder: BLURRED
                imgixParams: { fit: "crop", duotone: "333333,279BD4" }
              )
            }
          }
          image {
            gatsbyImageData(
              width: 1200
              height: 800
              placeholder: BLURRED
              imgixParams: { fit: "crop" }
            )
          }
        }
      }
    }
  }
`
