import { Container, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Banner from "./banner"

const LatestEdition = ({ banner, image, locale }) => {
  //   console.log(banner, image)
  return (
    <Container>
      <Flex
        sx={{ flexDirection: ["column", "row", "row"], position: "relative" }}
      >
        <Banner banner={banner} locale={locale} />
        <Flex
          sx={{
            width: ["100%", "50%", "50%"],
            ".gatsby-image-wrapper-constrained": {
              display: "block",
            },
          }}
        >
          <GatsbyImage image={image.gatsbyImageData} />
        </Flex>
      </Flex>
    </Container>
  )
}

export default LatestEdition
