import { Container, Flex } from "@theme-ui/components"
import React from "react"
import BannerPlus from "./bannerPlus"

const FeaturedBanner = ({ banners }) => {
  // console.log(banners)
  return (
    <Container>
      <Flex
        sx={{ flexDirection: ["column", "row", "row"], position: "relative" }}
      >
        {banners.map((banner, index) => (
          <BannerPlus banner={banner} index={index} key={banner.id} />
        ))}
      </Flex>
    </Container>
  )
}

export default FeaturedBanner
