import React from "react"
import { Flex, Text, Grid, Box } from "@theme-ui/components"
import ButtonLink from "./buttonLink"

const BannerPlus = ({ banner, index }) => {
  // console.log(banner)
  return (
    <Flex
      sx={{
        width: ["100%"],
        position: "relative",
        backgroundColor: "#FBDF4B",
      }}
    >
      <Grid columns={[1, 1, "1fr 1.5fr", 2]}>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "20rem",
          }}
          p={[3, 3, 5]}
        >
          <Text
            as="h4"
            sx={{
              color: "light",
              fontSize: [5, 5, 5, 6],
              fontWeight: "normal",
              width: ["100%", "100%", "100%", "80%"],
              wordWrap: "initial",
              lineHeight: "heading",
            }}
            mb={0}
          >
            {banner.title}
          </Text>
          <Flex sx={{ flexWrap: "wrap" }}>
            <ButtonLink url={banner.url} anchorText={banner.anchorText} />
            {banner.pdf && (
              <ButtonLink url={banner.pdf.url} anchorText="Scarica Pdf" />
            )}
          </Flex>
        </Flex>
        <Box
          sx={{
            width: ["100%"],
            position: "relative",
            overflow: "hidden",
            minHeight: ["20rem", "24rem", "24rem", "28rem"],
            backgroundImage: `url(${
              banner.image
                ? index !== 0
                  ? banner.image.blue.images.fallback.src
                  : banner.image.gray.images.fallback.src
                : null
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#FBDF4B",
          }}
        ></Box>
      </Grid>
    </Flex>
  )
}

export default BannerPlus
