import React from "react"
import { Flex, Text } from "@theme-ui/components"
import ButtonLink from "./buttonLink"
import { getCategoryPath } from "../utils/path"
import { i18nContext } from "../context/i18nContext"

const Banner = ({ banner, index, locale }) => {
  // console.log(banner)
  return (
    <i18nContext.Consumer>
      {t => (
        <Flex
          sx={{
            width: ["100%", "50%", "50%"],
            position: "relative",
            overflow: "hidden",
            backgroundImage: `url(${
              banner.image
                ? index !== 0
                  ? banner.image.blue.images.fallback.src
                  : banner.image.gray.images.fallback.src
                : null
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "primary",
          }}
          p={[3, 3, 5]}
        >
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "20rem",
            }}
          >
            <Text
              as="h4"
              sx={{
                color: "light",
                fontSize: [5, 5, 5, 6],
                fontWeight: "normal",
                width: ["100%", "100%", "100%", "80%"],
                wordWrap: "initial",
                lineHeight: "heading",
              }}
              mb={0}
            >
              {banner.title}
            </Text>
            <Flex sx={{ flexWrap: "wrap" }}>
              <ButtonLink
                target="blank"
                url={banner.url}
                anchorText={banner.anchorText}
              />

              <ButtonLink
                url={getCategoryPath(
                  banner.category.slug,
                  banner.category.locale
                )}
                anchorText={t.anchorText}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </i18nContext.Consumer>
  )
}

export default Banner
