import React from "react"
import { Flex, Link, Text, Image } from "@theme-ui/components"
import iconArrow from "../images/icon-arrow.svg"
import { motion } from "framer-motion"

const ButtonLink = ({ anchorText, url, target }) => (
  <MotionLink
    href={url}
    target={target}
    rel="noopener nofollow"
    sx={{ textDecoration: "none", mr: [5], mt: [2] }}
    whileHover="hover"
  >
    <Flex
      sx={{
        img: { marginBottom: 0, marginRight: 3 },
        alignItems: "center",
      }}
    >
      <AnimatedArrow variants={textMotion} src={iconArrow} alt="" />
      <Text
        as="p"
        sx={{
          color: "light",
          fontSize: [3],
          fontWeight: 500,
          borderBottom: "2px solid",
          borderColor: "light",
        }}
        mb={0}
      >
        {anchorText}
      </Text>
    </Flex>
  </MotionLink>
)

const MotionLink = motion(Link)

const AnimatedArrow = motion(Image)

const textMotion = {
  rest: {
    rotate: 0,
    transition: {
      duration: 2,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    rotate: 45,
    transition: {
      duration: 0.15,
      type: "tween",
      ease: "easeOut",
    },
  },
}

export default ButtonLink
