import { Box, Container, Grid, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { render } from "datocms-structured-text-to-plain-text"
import ArticleThumb from "./articleThumb"
import Link from "./utils/link"
import { getArticlePath } from "../utils/path"

const FeaturedArticles = ({ title, articles }) => {
  const mainArticle = articles[0]
  return (
    <Container>
      <Text
        variant="h1"
        as="h1"
        sx={{
          fontSize: 6,
          fontWeight: 400,
          color: "dark",
          lineHeight: "heading",
        }}
        mb={4}
      >
        {title}
      </Text>
      <Grid columns={[1, 1, "5fr 2fr", "3fr 1fr"]} gap={[3, 3, 5]}>
        <Link to={`/${getArticlePath(mainArticle.slug, mainArticle.locale)}`} variant="articleThumb">
          <Box>
            <Box mb={[2, 2, 3, 4]}>
              <GatsbyImage image={mainArticle.image.gatsbyImageData} alt="" />
            </Box>
            <Grid columns={[1, 1, 1, "2fr 3fr"]}>
              <Text
                as="h4"
                sx={{
                  fontSize: 5,
                  fontWeight: 500,
                  color: "dark",
                  lineHeight: "heading",
                  mb: [1, 1, 3],
                }}
              >
                {mainArticle.title}
              </Text>
              <Box>
                <Box mb={1} sx={{ width: "100%" }}>
                  <Text variant="caption" mr={2}>
                    {mainArticle.category.name}
                  </Text>
                  <Text variant="date" color="primary">
                    {mainArticle.meta.firstPublishedAt}
                  </Text>
                </Box>
                <Text as="p" sx={{ fontSize: 1 }}>
                  {render(mainArticle.structuredBody).substring(0, 250) +
                    " ..."}
                </Text>
              </Box>
            </Grid>
          </Box>
        </Link>
        <Box>
          {articles.slice(1, 3).map(article => (
            <ArticleThumb article={article} key={article.id} />
          ))}
        </Box>
      </Grid>
    </Container>
  )
}

export default FeaturedArticles
